import { Box, Button, IconButton, useTheme } from '@mui/material'
import { useContext } from 'react'
import { ColorModeContext, tokens } from '../../theme'
import { useDispatch, useSelector } from 'react-redux'
// import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined'
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined'
import { setLogout } from '../../state'
import { Link } from 'react-router-dom'
// import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
// import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
// import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
// import SearchIcon from "@mui/icons-material/Search";
// import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";

const Topbar = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)
  const colors = tokens(theme.palette.mode)
  const colorMode = useContext(ColorModeContext)

  return (
    <Box display="flex" justifyContent="right" pt={2} pr={4}>
      {/* SEARCH BAR */}
      {/*<Box*/}
      {/*  display="flex"*/}
      {/*  backgroundColor={colors.primary[400]}*/}
      {/*  borderRadius="3px"*/}
      {/*>*/}
      {/*  <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />*/}
      {/*  <IconButton type="button" sx={{ p: 1 }}>*/}
      {/*    <SearchIcon />*/}
      {/*  </IconButton>*/}
      {/*</Box>*/}

      {/* ICONS */}
      <Box display="flex">
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === 'dark' ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>
        {/*<IconButton>*/}
        {/*  <NotificationsOutlinedIcon />*/}
        {/*</IconButton>*/}
        {/*<IconButton>*/}
        {/*  <SettingsOutlinedIcon />*/}
        {/*</IconButton>*/}
        {/*<IconButton>*/}
        {/*  <PersonOutlinedIcon />*/}
        {/*</IconButton>*/}
        <div>
          <Link to="/">
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: '12px',
                fontWeight: 'bold',
                padding: '7px 7px',
              }}
              onClick={() => dispatch(setLogout())}
            >
              Logout
            </Button>
          </Link>
        </div>
      </Box>
    </Box>
  )
}

export default Topbar
