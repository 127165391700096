import { Box, useMediaQuery, colors, Typography } from '@mui/material'
import Form from './Form'

const LoginPage = () => {
  // const theme = useTheme()
  const isNonMobileScreens = useMediaQuery('(min-width: 1000px)')
  return (
    <Box>
      <Box
        width="100%"
        backgroundColor="#e4f0e2"
        p="1rem 6%"
        textAlign="center"
      >
        <Typography
          fontWeight="bold"
          fontSize="32px"
          color="secondary"
          py="20px"
        >
          Login to SaaS Account
        </Typography>
      </Box>

      <Box
        width={isNonMobileScreens ? '50%' : '93%'}
        p="2rem"
        m="2rem auto"
        borderRadius="1.5rem"
        backgroundColor="black"
      >
        <h1 fontWeight="500" variant="h5" sx={{ mb: '1.5rem' }}>
          Welcome to SaaS, login to access you Company's Dashboard
        </h1>
        <Form />
      </Box>
    </Box>
  )
}

export default LoginPage
