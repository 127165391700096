import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  useTheme,
  CircularProgress,
} from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { tokens } from '../../theme'
import Header from '../../components/Header'

const Team = () => {
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)
  const [isLoading, setIsLoading] = useState(true)
  const [newData, setNewData] = useState([])
  const [selectedUser, setSelectedUser] = useState(null)
  const token = useSelector((state) => state.token)
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false)

  const handleConfirmationDialogOpen = (email) => {
    setSelectedUser(email)
    setConfirmationDialogOpen(true)
  }

  const handleConfirmationDialogClose = () => {
    setSelectedUser(null)
    setConfirmationDialogOpen(false)
  }

  const handleRemoveUser = async () => {
    console.log(selectedUser)

    const deleteUserResponse = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/users/deleteUser`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          email: selectedUser,
        },
      }
    )
    console.log(deleteUserResponse)

    if (deleteUserResponse.ok) {
      const updatedData = newData.filter(
        (entry) => entry.email !== selectedUser
      )
      setNewData(updatedData)
    }

    handleConfirmationDialogClose()
  }

  useEffect(() => {
    const fetchUsers = async () => {
      const loggedInResponse = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/users/`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      const data = await loggedInResponse.json()

      const newD = data.map((entry) => ({
        ...entry,
        id: Math.random(),
      }))

      setNewData(newD)

      console.log(newD)
      setIsLoading(false)
    }

    fetchUsers()
  }, [])

  const columns = [
    { field: 'id', headerName: 'ID', flex: 0.5 },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      cellClassName: 'name-column--cell',
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
    },
    {
      field: 'mobile',
      headerName: 'Phone Number',
      flex: 1,
    },
    {
      field: 'pinCode',
      headerName: 'Zip Code',
      flex: 1,
    },
    {
      field: 'jobTitle',
      headerName: 'Job Title',
      flex: 1,
    },
    {
      field: 'category',
      headerName: 'Category',
      flex: 1,
    },
    {
      field: 'accessLevel',
      headerName: 'Access Level',
      flex: 1,
      renderCell: (params) => {
        const { email } = params.row

        return (
          <Box
            width="60%"
            m="0 auto"
            p="6px"
            display="flex"
            justifyContent="center"
            backgroundColor={colors.greenAccent[700]}
            borderRadius="4px"
          >
            <Button onClick={() => handleConfirmationDialogOpen(email)}>
              <Typography color={colors.grey[100]}>Remove User</Typography>
            </Button>
          </Box>
        )
      },
    },
  ]

  return (
    <>
      <Box m="20px">
        <Header title="TEAM" subtitle="Managing the Team Members" />
        <Box
          m="40px 0 0 0"
          height="75vh"
          sx={{
            '& .MuiDataGrid-root': {
              border: 'none',
            },
            '& .MuiDataGrid-cell': {
              borderBottom: 'none',
            },
            '& .name-column--cell': {
              color: colors.greenAccent[300],
            },
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: colors.blueAccent[700],
              borderBottom: 'none',
            },
            '& .MuiDataGrid-virtualScroller': {
              backgroundColor: colors.primary[400],
            },
            '& .MuiDataGrid-footerContainer': {
              borderTop: 'none',
              backgroundColor: colors.blueAccent[700],
            },
            '& .MuiCheckbox-root': {
              color: `${colors.greenAccent[200]} !important`,
            },
            '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
              color: `${colors.grey[100]} !important`,
            },
          }}
        >
          {isLoading ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '15rem',
              }}
            >
              <CircularProgress color="success" />
            </Box>
          ) : (
            <DataGrid rows={newData} columns={columns} />
          )}
        </Box>
      </Box>
      <Dialog
        open={confirmationDialogOpen}
        onClose={handleConfirmationDialogClose}
      >
        <DialogTitle>Confirm User Removal</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to remove the selected user?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleConfirmationDialogClose}
            variant="contained"
            color="error"
          >
            Cancel
          </Button>
          <Button
            onClick={handleRemoveUser}
            autoFocus
            variant="contained"
            color="success"
          >
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default Team
