import { useNavigate } from 'react-router-dom'
import { Box } from '@mui/system'
import { Typography } from '@mui/material'
import { Button } from '@mui/material'

const HomePage = () => {
  let navigate = useNavigate()

  const clickHandler = () => {
    navigate('/login')
  }

  return (
    <>
      <Box
        sx={{
          width: '100%',
          height: '10%',
          backgroundColor: '#D3D3D3',
          px: '50px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          textAlign: 'center',
          boxShadow: 'white',
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Monospace',
            fontSize: '40px',
            fontWeight: 'bold',
            color: 'green',
          }}
        >
          {' '}
          SaaS
        </Typography>

        <Button
          sx={{ px: '80px', fontSize: '20px', fontFamily: 'monospace' }}
          variant="contained"
          onClick={clickHandler}
        >
          Customer Login
        </Button>
      </Box>

      <Box
        sx={{
          width: '100%',
          height: '60%',
          backgroundColor: '#D3D3D3',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mt: '10px',
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Monospace',
            fontSize: '40px',
            fontWeight: 'bold',
            color: 'blue',
          }}
        >
          Beta Version Launching Soon
        </Typography>
      </Box>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          backgroundColor: '#D3D3D3',
        }}
      ></Box>
    </>
  )
}

export default HomePage
