import { Box, CircularProgress } from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { tokens } from '../../theme'
import Header from '../../components/Header'
import { useTheme } from '@mui/material'
import { useSelector } from 'react-redux'
import { useState, useEffect } from 'react'

const Contacts = () => {
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)
  const [loading, isloading] = useState(true)
  const [new_data, setnew_data] = useState('{}')
  const user = useSelector((state) => state.user)
  const token = useSelector((state) => state.token)

  console.log(user)
  const a = localStorage.getItem('persist:root')

  console.log(a)

  useEffect(() => {
    const fetchUsers = async () => {
      const loggedInResponse = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/users/`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      const data = await loggedInResponse.json()

      const newD = data.map((entry) => ({
        ...entry,
        id: Math.random(),
      }))

      setnew_data(newD)

      console.log(newD)
      isloading(false)
    }

    fetchUsers()
  }, [])

  // fetchUsers()
  const columns = [
    { field: 'id', headerName: 'ID', flex: 0.5 },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      cellClassName: 'name-column--cell',
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
    },
    {
      field: 'mobile',
      headerName: 'Phone Number',
      flex: 1,
    },
    {
      field: 'pinCode',
      headerName: 'Zip Code',
      flex: 1,
    },
    {
      field: 'jobTitle',
      headerName: 'Job Title',
      flex: 1,
    },
    {
      field: 'category',
      headerName: 'Category',
      flex: 1,
    },
  ]

  return (
    <Box m="20px">
      <Header
        title="CONTACTS"
        subtitle="List of Contacts for Future Reference"
      />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          '& .MuiDataGrid-root': {
            border: 'none',
          },
          '& .MuiDataGrid-cell': {
            borderBottom: 'none',
          },
          '& .name-column--cell': {
            color: colors.greenAccent[300],
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: colors.blueAccent[700],
            borderBottom: 'none',
          },
          '& .MuiDataGrid-virtualScroller': {
            backgroundColor: colors.primary[400],
          },
          '& .MuiDataGrid-footerContainer': {
            borderTop: 'none',
            backgroundColor: colors.blueAccent[700],
          },
          '& .MuiCheckbox-root': {
            color: `${colors.greenAccent[200]} !important`,
          },
          '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        {loading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '15rem',
            }}
          >
            <CircularProgress color="success" />
          </Box>
        ) : (
          <DataGrid
            rows={new_data}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
          />
        )}
      </Box>
    </Box>
  )
}

export default Contacts
