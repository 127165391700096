import { Box, Button, TextField, CircularProgress } from '@mui/material'
import { Formik } from 'formik'
import * as yup from 'yup'
import useMediaQuery from '@mui/material/useMediaQuery'
import Header from '../../components/Header'
import { useSelector } from 'react-redux'
import * as React from 'react'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'

const Form = () => {
  const isNonMobile = useMediaQuery('(min-width:600px)')
  const userCompany = useSelector((state) => state.user.company)
  const [isLoading, setIsLoading] = React.useState(false)
  const [successOpen, setSuccessOpen] = React.useState(false)
  const [errorOpen, setErrorOpen] = React.useState(false)

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
  })

  const handleFormSubmit = async (values, onSubmitProps) => {
    setIsLoading(true)
    const data = { company: userCompany, ...values }
    console.log(data)
    const signedInResponse = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/auth/signup`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      }
    )
    console.log(signedInResponse)
    onSubmitProps.resetForm()
    setIsLoading(false)
    if (signedInResponse.status === 200) setSuccessOpen(true)
    else setErrorOpen(true)
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setSuccessOpen(false)
    setErrorOpen(false)
  }

  return (
    <Box m="50px" ml="100px" mr="100px">
      <Header title="ADD USER" subtitle="Create a New User Profile" />
      {isLoading && (
        <Box
          sx={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}
        >
          <CircularProgress color="success" />
        </Box>
      )}
      <Snackbar
        open={successOpen}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          sx={{ width: '100%', fontSize: '15px' }}
        >
          User Successfully Added To The Organization
        </Alert>
      </Snackbar>
      <Snackbar open={errorOpen} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="error"
          sx={{ width: '100%', fontSize: '15px' }}
        >
          Error While Adding User To The Organization
        </Alert>
      </Snackbar>
      {!isLoading && (
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={checkoutSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  '& > div': { gridColumn: isNonMobile ? undefined : 'span 4' },
                }}
              >
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.name}
                  name="name"
                  error={!!touched.name && !!errors.name}
                  helperText={touched.name && errors.name}
                  sx={{ gridColumn: 'span 1' }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  name="email"
                  error={!!touched.email && !!errors.email}
                  helperText={touched.email && errors.email}
                  sx={{ gridColumn: 'span 1' }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Phone Number"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.mobile}
                  name="mobile"
                  error={!!touched.mobile && !!errors.mobile}
                  helperText={touched.mobile && errors.mobile}
                  sx={{ gridColumn: 'span 1' }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Postal Code"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.pinCode}
                  name="pinCode"
                  error={!!touched.pinCode && !!errors.pinCode}
                  helperText={touched.pinCode && errors.pinCode}
                  sx={{ gridColumn: 'span 1' }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Category"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.category}
                  name="category"
                  error={!!touched.category && !!errors.category}
                  helperText={touched.category && errors.category}
                  sx={{ gridColumn: 'span 2' }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Job Title"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.jobTitle}
                  name="jobTitle"
                  error={!!touched.jobTitle && !!errors.jobTitle}
                  helperText={touched.jobTitle && errors.jobTitle}
                  sx={{ gridColumn: 'span 2' }}
                />
              </Box>
              <Box display="flex" justifyContent="end" mt="20px">
                <Button type="submit" color="secondary" variant="contained">
                  Create New User
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      )}
    </Box>
  )
}

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/

const checkoutSchema = yup.object().shape({
  name: yup.string().required('required'),
  email: yup.string().email('invalid email').required('required'),
  mobile: yup
    .string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .required('required'),
  pinCode: yup.string().required('required'),
  category: yup.string().required('required'),
  jobTitle: yup.string().required('required'),
})
const initialValues = {
  name: '',
  email: '',
  mobile: '',
  pinCode: '',
  category: '',
  jobTitle: '',
}

export default Form
