import { Routes, Route, Navigate } from 'react-router-dom'
import Topbar from './scenes/navbars/Topbar'
import Sidebar from './scenes/navbars/Sidebar'
import Dashboard from './scenes/dashboard'
import Contacts from './scenes/contacts'
import Team from './scenes/team'
import Form from './scenes/addUser'
import FAQ from './scenes/extras/faq'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { ColorModeContext, useMode } from './theme'
import Calendar from './scenes/extras/calendar/calendar'
import LoginPage from './scenes/loginPage'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import ProfilePage from './scenes/profile'
import HomePage from './scenes/home'
import { GoogleOAuthProvider } from '@react-oauth/google'
import SaasApps from './scenes/saasApps'

function App() {
  const [theme, colorMode] = useMode()
  const [isSidebar, setIsSidebar] = useState(true)
  const isAuth = Boolean(useSelector((state) => state.token))

  return (
    <ColorModeContext.Provider value={colorMode}>
      <GoogleOAuthProvider
        clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}
      >
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className="app">
            {isAuth ? <Sidebar isSidebar={isSidebar} /> : null}
            <main className="content">
              {isAuth ? <Topbar setIsSidebar={setIsSidebar} /> : null}
              <Routes>
                <Route
                  path="/login"
                  element={
                    !isAuth ? <LoginPage /> : <Navigate to={'/Dashboard'} />
                  }
                />
                <Route path="/login" element={<LoginPage />} />
                <Route
                  path="/"
                  element={
                    isAuth ? <Navigate to={'/Dashboard'} /> : <HomePage />
                  }
                />
                {/* <Route path="/" element={<HomePage />} /> */}
                <Route
                  path="/profile"
                  element={
                    isAuth ? <ProfilePage /> : <Navigate to={'/login'} />
                  }
                />
                <Route
                  path="/dashboard"
                  element={isAuth ? <Dashboard /> : <Navigate to={'/login'} />}
                />
                <Route
                  path="/saas-apps"
                  element={true ? <SaasApps /> : <Navigate to={'/login'} />}
                />
                <Route
                  path="/team"
                  element={isAuth ? <Team /> : <Navigate to={'/'} />}
                />
                <Route
                  path="/contacts"
                  element={isAuth ? <Contacts /> : <Navigate to={'/'} />}
                />
                <Route
                  path="/form"
                  element={isAuth ? <Form /> : <Navigate to={'/'} />}
                />
                <Route
                  path="/faq"
                  element={isAuth ? <FAQ /> : <Navigate to={'/'} />}
                />
                <Route
                  path="/calendar"
                  element={isAuth ? <Calendar /> : <Navigate to={'/'} />}
                />
              </Routes>
            </main>
          </div>
        </ThemeProvider>
      </GoogleOAuthProvider>
    </ColorModeContext.Provider>
  )
}

export default App
