import { Box, Button, TextField } from '@mui/material'
import { Formik } from 'formik'
import * as yup from 'yup'
import useMediaQuery from '@mui/material/useMediaQuery'
import Header from '../../components/Header'
import { useSelector } from 'react-redux'
import { Typography } from '@mui/material'

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/

const checkoutSchema = yup.object().shape({
  name: yup.string().required('required'),
  email: yup.string().email('invalid email').required('required'),
  mobile: yup
    .string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .required('required'),
  pinCode: yup.string().required('required'),
  category: yup.string().required('required'),
  jobTitle: yup.string().required('required'),
})

const ProfilePage = () => {
  const isNonMobile = useMediaQuery('(min-width:600px)')
  const user = useSelector((state) => state.user)

  const initialValues = {
    name: user.name,
    email: user.email,
    mobile: user.mobile,
    pinCode: user.pinCode,
    category: user.category,
    jobTitle: user.jobTitle,
  }

  const handleFormSubmit = (values) => {
    console.log(values)
  }

  return (
    <Box m="50px" ml="100px" mr="100px">
      <Header title="Profile" subtitle="Update user profile" />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                '& > div': { gridColumn: isNonMobile ? undefined : 'span 4' },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Name"
                value={values.name}
                name="name"
                sx={{ gridColumn: 'span 1' }}
                readOnly
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Email"
                value={values.email}
                name="email"
                sx={{ gridColumn: 'span 1' }}
                readOnly
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Phone Number"
                value={values.mobile}
                name="mobile"
                sx={{ gridColumn: 'span 1' }}
                readOnly
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Postal Code"
                value={values.pinCode}
                name="pinCode"
                sx={{ gridColumn: 'span 1' }}
                readOnly
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Category"
                value={values.category}
                name="category"
                sx={{ gridColumn: 'span 2' }}
                readOnly
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Job Title"
                value={values.jobTitle}
                name="jobTitle"
                sx={{ gridColumn: 'span 2' }}
                readOnly
              />
            </Box>
            <Box display="flex" mt="40px" flexDirection="column">
              <Typography
                sx={{
                  fontFamily: 'monospace',
                  fontSize: '15px',
                  fontWeight: 'bold',
                }}
              >
                To update your details kindly contact your organisation admin.
              </Typography>
              <Typography
                sx={{
                  pt: '20px',
                  fontFamily: 'monospace',
                  fontSize: '15px',
                  color: 'White',
                  fontWeight: 'bold',
                }}
              >
                Password change facility will be coming soon.
              </Typography>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  )
}

export default ProfilePage
