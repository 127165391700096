import {
  Box,
  Button,
  CircularProgress,
  TextField,
  useMediaQuery,
} from '@mui/material'
import { Formik } from 'formik'
import * as yup from 'yup' //validation library
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setLogin } from '../../state'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'
import * as React from 'react'

const loginSchema = yup.object().shape({
  email: yup.string().email('invalid email').required('required'),
  password: yup.string().required('required'),
})

const initialValuesLogin = {
  email: '',
  password: '',
}

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

const Form = () => {
  const [open, setOpen] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isNonMobile = useMediaQuery('(min-width:600px)')

  const handleClick = () => {
    setOpen(true)
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  const login = async (values, onSubmitProps) => {
    setIsLoading(true)
    const loggedInResponse = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/auth/login`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(values),
      }
    )
    const loggedIn = await loggedInResponse.json()
    console.log(loggedIn)
    onSubmitProps.resetForm()
    if (!loggedIn.token) {
      console.log('error')
      setOpen(true)
      setIsLoading(false)
    } else {
      // setTimeout(() => {
      setIsLoading(false) // Stop the loader after 3 seconds
      dispatch(
        setLogin({
          user: loggedIn.user,
          token: loggedIn.token,
        })
      )
      navigate('/dashboard')
      // }, 3000)
    }
  }

  const handleFormSubmit = async (values, onSubmitProps) => {
    login(values, onSubmitProps)
  }

  return (
    <>
      {isLoading && (
        <Box
          sx={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}
        >
          <CircularProgress color="success" />
        </Box>
      )}
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="error"
          sx={{ width: '100%', fontSize: '15px' }}
        >
          Sorry, Invalid Credentials
        </Alert>
      </Snackbar>
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValuesLogin}
        validationSchema={loginSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          resetForm,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                '& > div': {
                  gridColumn: isNonMobile ? undefined : 'span 4',
                },
              }}
            >
              <TextField
                label="Email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                name="email"
                error={Boolean(touched.email) && Boolean(errors.email)}
                helperText={touched.email && errors.email}
                sx={{ gridColumn: 'span 4' }}
              />
              <TextField
                label="Password"
                type="password"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.password}
                name="password"
                error={Boolean(touched.password) && Boolean(errors.password)}
                helperText={touched.password && errors.password}
                sx={{ gridColumn: 'span 4' }}
              />
            </Box>

            <Box>
              <Button
                fullWidth
                type="submit"
                sx={{
                  m: '2rem 0',
                  p: '1rem',
                  backgroundColor: '#FFFFFF',
                  color: '#000000',
                  '&:hover': { color: '#FFFFFF,' },
                }}
              >
                LOGIN
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </>
  )
}

export default Form
